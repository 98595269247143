import { css } from 'styled-components'
import theme, {bp, color} from 'settings/theme'

const styles = css`


    html{
        font-family: ${theme.typography.fontFamilyDefault};
    }

    body{
        color: ${color.text};
        line-height: 1.6;
    }

    ::selection {
        color: ${color.selectedText};
        background: ${color.selectedTextBackground};
    }

    p{
        margin-bottom: 1rem;
    }

    a{
        text-decoration: none;
    }



    /* Text Size */
    html{

        font-size: 14px;

        @media (min-width: ${bp(3)}) {
            font-size: 16px;
        }

    }
    


    /* Headings */

    h1, [h1], .h1{ 
        color: ${color.primary};
        font-family: ${theme.typography.fontFamilySecondary};
        font-size: 22px;
        line-height: 1.2;
    }

    h2, [h2], .h2{
        font-family: ${theme.typography.fontFamilySecondary};
        font-size: 20px;
        line-height: 1.2;
    }

    h3, [h3], .h3{
        font-family: ${theme.typography.fontFamilySecondary};
        font-size: 18px;
        line-height: 1.2;
    }

    @media (min-width: ${bp(3)}) {

        h1, [h1], .h1{
            font-size: 30px;
        }

        h2, [h2], .h2{
            font-size: 26px;
        }

        h3, .h3, .h3{
            font-size: 22px;
        }

    }

    .textSize80{font-size:0.8rem;}
    .textSize90{font-size:0.9rem;}
    .textSize120{font-size:1.2rem;}


`

export default styles
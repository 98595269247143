import styled, {css} from 'styled-components'
import theme, {bp} from 'settings/theme'
import {multiplyValueWithUnits} from 'utils/units'

const generate = (selector, properties, values, multiplier) => {

  return css`

    ${selector}{
      ${properties.map(((prop, index) => {
        return `${properties[index]}:${multiplyValueWithUnits(values[0], multiplier)};`
      }))}
    }

    @media (min-width: ${bp(2)}) {
      ${selector}{
        ${properties.map(((prop, index) => {
          return `${properties[index]}:${multiplyValueWithUnits(values[1], multiplier)};`
        }))}
      }
    }

    @media (min-width: ${bp(3)}) {
      ${selector}{
        ${properties.map(((prop, index) => {
          return `${properties[index]}:${multiplyValueWithUnits(values[2], multiplier)};`
        }))}
      }
    }

    @media (min-width: ${bp(4)}) {
      ${selector}{
        ${properties.map(((prop, index) => {
          return `${properties[index]}:${multiplyValueWithUnits(values[3], multiplier)};`
        }))}
      }
    }

    @media (min-width: ${bp(5)}) {
      ${selector}{
        ${properties.map(((prop, index) => {
          return `${properties[index]}:${multiplyValueWithUnits(values[4], multiplier)};`
        }))}
      }
    }

    @media (min-width: ${bp(6)}) {
      ${selector}{
        ${properties.map(((prop, index) => {
          return `${properties[index]}:${multiplyValueWithUnits(values[5], multiplier)};`
        }))}
      }
    }
      
  `;

}

const spacingVertical = generate('.spacingVertical', ['margin-top', 'margin-bottom'], theme.spacing.spacingVertical, 1);
const spacingTop = generate('.spacingTop', ['margin-top'], theme.spacing.spacingVertical, 1);
const spacingBottom = generate('.spacingBottom', ['margin-bottom'], theme.spacing.spacingVertical, 1);
const spacingBottomHalf = generate('.spacingBottomHalf', ['margin-bottom'], theme.spacing.spacingVertical, 0.5);
const spacingBottomDouble = generate('.spacingBottomDouble', ['margin-bottom'], theme.spacing.spacingVertical, 2);

const paddingHorizontal = generate('.paddingHorizontal', ['padding-left', 'padding-right'], theme.spacing.paddingHorizontal, 1);
const paddingVertical = generate('.paddingVertical', ['padding-top', 'padding-bottom'], theme.spacing.paddingVertical, 1);
const paddingVerticalAsSpacing = generate('.paddingVerticalAsSpacing', ['padding-top', 'padding-bottom'], theme.spacing.spacingVertical, 1);
const paddingTop = generate('.paddingTop', ['padding-top'], theme.spacing.paddingVertical, 1);
const paddingTopAsSpacing = generate('.paddingTopAsSpacing', ['padding-top'], theme.spacing.spacingVertical, 1);
const paddingBottom = generate('.paddingBottom', ['padding-bottom'], theme.spacing.paddingVertical, 1);
const paddingOuterGutters = generate('.paddingOuterGutters', ['padding-left', 'padding-right'], theme.spacing.outerGutterWidth, 1);

export {

  spacingVertical,
  spacingTop,
  spacingBottom,
  spacingBottomHalf,
  spacingBottomDouble,

  paddingHorizontal,
  paddingVertical,
  paddingVerticalAsSpacing,
  paddingTop,
  paddingTopAsSpacing,
  paddingBottom,
  paddingOuterGutters

};
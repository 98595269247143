import React, {useState, useReducer, useRef, useEffect, Dispatch, SetStateAction} from 'react';
import { useRouter } from 'next/router';

import config from 'settings/config';
import productTypes from 'settings/productTypes';
import searchParamTypes from 'settings/searchParamTypes';

import algoliaService from 'services/algoliaService';


type ContextProps = { 
  clearParams: () => void,
  doSearch: () => void,
  facets: {[key: string]: SearchFacet},
  isLoading: boolean,
  lockedFilters: string[],
  meta: StateMeta
  params: ObjectOfStrings,
  paramTypes: SearchParamType[],
  parseUrl: () => void,
  removeParam: (paramName: string) => void,
  results,
  setIsLoading: (value: boolean) => void,
  setParams: (newParams: ObjectOfStrings) => void,
  setParam: (name: string, value: string) => void,
  setSortBy: (value: string) => void
};

type StateMeta = {
  sortBy: 'popularity' | 'buypricelohi' | 'buypricehilo',
  page: number,
  hitsPerPage: number,
  totalHits: number
};

type StateProps = { 
  facets: {[key: string]: SearchFacet},
  isLoading: boolean,
  lockedFilters: string[],
  meta: StateMeta,
  params: ObjectOfStrings,
  paramTypes: SearchParamType[],
  queryParamsToPass: ObjectOfStrings,
  requestResults: boolean,
  requestUpdatedUrl: boolean,
  results: any[]
};


// Empty State
const initialState: StateProps = {
  facets: {},
  isLoading: true,
  lockedFilters: [],
  meta: {
    sortBy: 'popularity',
    page: 1,
    hitsPerPage: config.search.resultsPerPage,
    totalHits: 0
  },
  params: {},
  paramTypes: [],
  queryParamsToPass: {},
  requestResults: false,
  requestUpdatedUrl: false,
  results: []
};


// Reducer
const reducer = (state: StateProps, action) => {

  switch (action.type) {

    case 'clearParams': {

      let newParams = {};

      for (const [ key, value ] of Object.entries(state.params)) {
      
        if(state.lockedFilters.includes(key.toLowerCase())){
          newParams[key] = value;
        }

      }

      return { ...state, params: newParams};

    }

    case 'removeParam': {
      let {[action.payload]: ignoredValue, ...rest} = state.params;
      return { ...state, params: rest};
    }

    case 'setIsLoading': {
      return { ...state, isLoading: action.payload};
    }

    case 'setFacet': {

      let facetName = action.payload.name?.toLowerCase();

      return { 
        ...state, 
        facets: {
          ...state.facets, 
          [facetName]: action.payload.value
        }
      };

    }

    case 'setFacets': {
      return { ...state, facets: action.payload};
    }

    case 'setLockedFilters': {

      let newLockedFitlers = action.payload ?? [];
      newLockedFitlers = newLockedFitlers.map(item => item.toLowerCase());

      return { ...state, lockedFilters: action.payload};

    }

    case 'setMeta': {
      return { ...state, meta: action.payload};
    }

    case 'setParam': {

      let newParams = { 
        ...state, 
        params: {
          ...state.params,
          [action.payload.name]: action.payload.value
        }
      };

      if(action.payload.value === null || action.payload.value === undefined || action.payload.value === ""){
        delete newParams.params[action.payload.name];
      }

      return newParams;

    }

    case 'setParams': {
      return { ...state, params: action.payload};
    }

    case 'setParamTypes': {
      return { ...state, paramTypes: action.payload};
    }

    case 'setQueryParamsToPass': {
      return { ...state, queryParamsToPass: action.payload};
    }

    case 'setRequestResults': {
      return { ...state, requestResults: action.payload};
    }

    case 'setRequestUpdatedUrl': {
      return { ...state, requestUpdatedUrl: action.payload};
    }

    case 'setResults': {
      return { ...state, results: action.payload};
    }

    case 'setSortBy': {
      return { 
        ...state, 
        meta: {
          ...state.meta, 
          sortBy: action.payload
        }
      };
    }

    default: {
      return state;
    }

  }

}


// Hook
function SearchContextValue() {

  const router = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);

  // Clear all parameters
  const clearParams = () => {
    dispatch({type: 'clearParams'});
  };
  
  
  // Get results from Algolia every time they're requested
  useEffect(() => {

    if(!state.requestResults){return;}

    dispatch({type: 'setRequestResults', payload: false});

    // Set loading indicator
    dispatch({type: 'setIsLoading', payload: true});
    
    // Set keywords to at least an empty string
    const keywords = (state.params.keywords ?? '') as string;

    // Set search parameters
    const searchParams: AlgoliaQueryParams = {
      page: state.meta.page - 1,
      hitsPerPage: config.search.resultsPerPage,
      facets: ['*']
    };

    // Index will default to Products
    let indexName: string;

    // Sorting
    let sortValues = {
      'popularity': 'products_sort_popularity_hilo',
      'pricelohi' : 'products_sort_price_lohi',
      'pricehilo' : 'products_sort_price_hilo'
    };

    if(state.meta.sortBy){
      indexName = sortValues[state.meta.sortBy];
    }
    else{
      indexName = 'products_sort_popularity_hilo';
    }


    // Filters

    // All searches can use the generic params
    let paramTypeNames: string[] = productTypes.all.paramTypes;

    // If the product type is set then get the additional param types we can use
    if(state.params.product_type && productTypes[state.params.product_type]){

      paramTypeNames = [...paramTypeNames, ...productTypes[state.params.product_type].paramTypes];

      // Remove duplicates from the array
      paramTypeNames = paramTypeNames.filter((item, index) => paramTypeNames.indexOf(item) === index);

    }

    // Replace the names of the param types with an array of detailed info about the param types
    let paramTypes: SearchParamType[] = paramTypeNames.map(item => ({...searchParamTypes[item]}));


    // Set the param types
    dispatch({type: 'setParamTypes', payload: paramTypes });

    // Create en empty filters array
    let filtersArr: {name: string, value: string}[] = [];

    // Loop through all the allowed params
    paramTypes.forEach(paramType => {
        
      // If there is a value set in the url for this param
      if(state.params[paramType.name]){
          
        switch (paramType.type) {

          case 'string':

            filtersArr.push({
              name: paramType.name, 
              value: `${paramType.algoliaFacetName}:"${state.params[paramType.name]}"`
            });

            break;

          case 'list':

            const values = state.params[paramType.name].split('|');
            const items = values.map(item => `${paramType.algoliaFacetName}:"${item}"`);
            const newFilter = `(${items.join(' OR ')})`;

            filtersArr.push({name: paramType.name, value: newFilter});

            break;
        
        }

      }

    })


    // Add any constant filters
    filtersArr.push({
      name: 'status', 
      value: `status:live`
    });


    // Convert the filters to a string to send to Algolia
    if(filtersArr && filtersArr.length > 0){
      searchParams.filters = filtersArr.map(item => item.value).join(' AND ');
    }


    // Load the results
    algoliaService.getResults(keywords, searchParams, {indexName}).then((result: any) => {

      // Set the results
      dispatch({type: 'setResults', payload: algoliaService.convertHitsToProducts(result.hits)});

      // Set the meta details
      let searchMeta = {
          ...state.meta,
          totalHits: result.nbHits ?? 0
      };
      dispatch({type: 'setMeta', payload: searchMeta});


      // Set the facets
      let newFacets: {[key: string]: SearchFacet} = {};

      // Loop through all the param type we're surrently using
      paramTypes.forEach(paramType => {

        let algoliaFacet: AlgoliaFacet = result.facets[paramType.algoliaFacetName!];
    
        // If we're not ignoring the real facet counts for this param, or the param isn't being used yet
        // Then we need to use the facet counts from the result set
        if((paramType.facetCountMode !== 'excludeCurrent' && paramType.facetCountMode !== 'excludeCurrentAndNotCommon') || state.params[paramType.name] === undefined){

          if(algoliaFacet){

            newFacets[paramType.name] = {
              options: createFacetOptions(state, paramType, algoliaFacet)
            }

          }

        }

        // If we are ignoring them we have to load the real ones
        else if(paramType.facetCountMode === 'excludeCurrent' || paramType.facetCountMode === 'excludeCurrentAndNotCommon'){

          // No point loading new reaults 
          if(algoliaFacet){

            // Set the values that will be used for now to show that we're loading new ones
            newFacets[paramType.name] = {
              loading: true,
              options: []
            };


            // Load new facet counts

            // Set the number of hits to 0 as we don't actually want any results
            let newFiltersArr = [...filtersArr];


            // Remove Current
            newFiltersArr = newFiltersArr.filter(item => {
              return item.name !== paramType.name;
            })

            // Only allow common
            if(paramType.facetCountMode === 'excludeCurrentAndNotCommon'){
              newFiltersArr = newFiltersArr.filter(item => {
                return ['price', 'product_type', 'availability', 'region', 'manufacturer', 'usage'].includes(item.name);
              })
            }


            let newSearchparams: AlgoliaQueryParams = {...searchParams, hitsPerPage: 0};
            delete newSearchparams.filters;

            if(newFiltersArr && newFiltersArr.length > 0){
              newSearchparams.filters = newFiltersArr.map(item => item.value).join(' AND ');
            }

            algoliaService.getResults(keywords, newSearchparams, {indexName}).then((result: any) => {

              let newAlgoliaFacet: AlgoliaFacet = result.facets[paramType.algoliaFacetName!];

              if(newAlgoliaFacet){
                let options = createFacetOptions(state, paramType, newAlgoliaFacet);
                dispatch({type: 'setFacet', payload: {name: paramType.name, value:{options}}});
              }

            })


          }

        }


      })


      dispatch({type: 'setFacets', payload: newFacets});

    }).catch((error) => {
        
      // TODO - Something better with this
      console.log("error");

    }).finally(() => {

      // Reset the loading indicator
      dispatch({type: 'setIsLoading', payload: false});

    })

  }, [state.requestResults]);


  // Update the URL every time it's requested
  useEffect(() => {

    if(!state.requestUpdatedUrl){return}

    dispatch({type: 'setRequestUpdatedUrl', payload: false});

    let query = state.params;

    if(state.meta.sortBy && state.meta.sortBy !== 'popularity'){
      query.sort_by = state.meta.sortBy;
    }

    if(state.lockedFilters && state.lockedFilters.length > 0){
      query.locked_filters = state.lockedFilters.join('|');
    }

    if(state.queryParamsToPass){
      for (const [ key, value ] of Object.entries(state.queryParamsToPass)) {
        query[key] = value;
      }
    }

    router.push({
      pathname: '/products',
      query: state.params,
    });

  }, [state.requestUpdatedUrl])


  // Do search
  const doSearch = () => {

    if(typeof window !== undefined){
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    }

    dispatch({type: 'setRequestUpdatedUrl', payload: true});

  };


  // Parse URL
  const parseUrl = () => {

    let urlParams: ObjectOfStrings = {...router.query as ObjectOfStrings};

    // Set the page number in meta, then remove it
    const newMeta = {
      ...state.meta,
      page: urlParams.page ? parseInt(urlParams.page) : 1,
      sortBy: urlParams.sort_by || 'popularity'
    };

    delete urlParams.page;
    delete urlParams.sort_by;

    // Loop through all query params and decode &s and ?s
    for (const [ key, value ] of Object.entries(urlParams)) {
      urlParams[key] = decodeURIComponent(value);
    }

    
    // Locked filters
    let lockedFilters: any[] = [];
    if(urlParams.locked_filters){
      lockedFilters = urlParams.locked_filters?.split('|');
      delete urlParams.locked_filters;
    }

    // Query Params to ignore but pass through then the URL changes
    let queryParamsToPass: ObjectOfStrings = {};
    if(urlParams.cat_id){
      queryParamsToPass.cat_id = urlParams.cat_id;
      delete urlParams.cat_id;
    }

    // console.log("TESTING", state.params.product_type)

    // // If product_type is set and wasn't previously then only allow the params which that product type has
    // if(urlParams.product_type && state.params.product_type === undefined){
    //  console.log("New product_type"); 
    // }

    // // If product_type is changed from the previous value then we should clear all but generic params
    // else if(urlParams.product_type && urlParams.product_type !== state.params.product_type){
    //   console.log("Changed product_type");
    // }

    

    // console.log("urlParams", urlParams);
    // console.log("urlParams.product_type", urlParams.product_type);

    // console.log("existing prduct type", state.params.product_type);

    dispatch({type: 'setMeta', payload: newMeta});
    dispatch({type: 'setParams', payload: urlParams});
    dispatch({type: 'setLockedFilters', payload: lockedFilters});
    dispatch({type: 'setQueryParamsToPass', payload: queryParamsToPass});
    dispatch({type: 'setRequestResults', payload: true});

  };


  // Remove a parameter
  const removeParam = (paramName: string) => {
    dispatch({type: 'removeParam', payload: paramName});
  };


  // Set isLoading
  const setIsLoading = (value: boolean) => {
    dispatch({type: 'setIsLoading', payload: value});
  };


  // Set Parameter
  const setParam = (name: string, value: string) => {

    if(name === 'product_type'){

      let newStateParams: ObjectOfStrings = {};
      let allowedParams = ['price','availability','region','manufacturer','keywords'];

      allowedParams.forEach(item => {
        if(state.params[item]){
          newStateParams[item] = state.params[item];
        }
      });

      if(value !== null && value !== undefined){
        newStateParams.product_type = value;
      }

      dispatch({type: 'setParams', payload: newStateParams});

    }
    else{
      dispatch({type: 'setParam', payload: {name, value}});
    }
      
  };


  // Set All Parameters
  const setParams = (newParams: ObjectOfStrings) => {
    dispatch({type: 'setParams', payload: newParams});
  };

  // Set Sorting Option
  const setSortBy = (value: string) => {
    dispatch({type: 'setSortBy', payload: value});
  };


  return {
      clearParams,
      doSearch,
      facets: state.facets,
      isLoading: state.isLoading,
      lockedFilters: state.lockedFilters,
      meta: state.meta,
      params: state.params,
      paramTypes: state.paramTypes,
      parseUrl,
      results: state.results,
      removeParam,
      setIsLoading,
      setParams,
      setParam,
      setSortBy
  };

}


let SearchContext = React.createContext<Partial<ContextProps>>({});


export {SearchContext, SearchContextValue};







// Create Facet Options
const createFacetOptions = (state:StateProps, paramType:SearchParamType, algoliaFacet:AlgoliaFacet ) => {

  let options: SearchFacetOption[] = []

  // If bands are being used
  if(paramType.bands){

    let bands = state.params.product_type ? paramType.bands[state.params.product_type] || paramType.bands.default : paramType.bands.default

    for (const [ name, value ] of Object.entries(bands)) {

      for (const [ algName, algVal ] of Object.entries(algoliaFacet)) {
        if(algName.toLowerCase() === name){
          options.push({
            name: name.toLowerCase(),
            friendlyName: bands[name].friendlyName,
            value: algVal as number
          })
        }
      }

    }

  }

  // If friendly names are being used
  else if(paramType.friendlyNames){
    for (const [ name, value ] of Object.entries(paramType.friendlyNames)) {

      for (const [ algName, algVal ] of Object.entries(algoliaFacet)) {
        if(algName.toLowerCase() === name){
          options.push({
            name: name.toLowerCase(),
            friendlyName: paramType.friendlyNames[name],
            value: algVal as number
          })
        }
      }
        
    }
  }

  // Otherwise just use the names from 
  else{
    for (const [ name, value ] of Object.entries(algoliaFacet)) {
      options.push({
        name: name.toLowerCase(),
        friendlyName: name,
        value: value as number
      })
    }
  }

  return options;

}

const searchParamsTypes: {[key: string]: SearchParamType} = {

  'price': {
    name: 'price',
    type: 'range',
    controlType: 'range',
    filterTitle: 'Price'
  },

  'product_type' : {
    name: 'product_type',
    type: 'string',
    algoliaFacetName: 'product_type',
    facetCountMode: 'excludeCurrentAndNotCommon',
    controlType: 'checkboxes',
    friendlyNames: {
      'manual-wheelchair': 'Manual Wheelchair',
      'active-wheelchair': 'Active Wheelchair',
      'powerchair': 'Powerchair',
      'scooter': 'Mobility Scooter',
      'ramp': 'Ramp',
      'bed': 'Bed',
      'chair': 'Chair',
      'power-add-on': 'Power Add-On',
      'knee-scooter': 'Knee Scooter',
      'rollator': 'Rollator',
      'stairlift': 'Stairlift',
      'hoist': 'Hoist',
      'cushion': 'Cushion',
      'backrest': 'Backrest',
      'accessory': 'Accessory',
    },
    filterTitle: 'Product Type',
  },

  'availability' : {
    name: 'availability',
    type: 'list',
    algoliaFacetName: 'availability.methods',
    facetCountMode: 'excludeCurrent',
    controlType: 'checkboxes',
    friendlyNames: {
      'buy': 'Purchase',
      'buy used': 'Approved Used',
      'short term hire': 'Short Term Hire',
      'long term hire': 'Long Term Hire',
      'motability': 'Motability'
    },
    filterTitle: 'Availability'
  },

  'region' : {
    name: 'region',
    type: 'list',
    algoliaFacetName: 'availability.region',
    facetCountMode: 'excludeCurrent',
    controlType: 'checkboxes',
    friendlyNames: {
        'nat': 'Nationwide',
        'se': 'London & SE Only'
    },
    filterTitle: 'Location'
  },

  'manufacturer': {
    name: 'manufacturer',
    type: 'list',
    algoliaFacetName: 'manufacturer',
    facetCountMode: 'excludeCurrent',
    controlType: 'checkboxes',
    filterTitle: 'Brand'
  },

  'wheelchair_type': {
    name: 'wheelchair_type',
    type: 'list',
    algoliaFacetName: 'specs.wheelchair_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Wheelchair Type'
  },

  'active_wheelchair_type': {
    name: 'active_wheelchair_type',
    type: 'list',
    algoliaFacetName: 'specs.active_wheelchair_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Wheelchair Type'
  },

  'powerchair_type': {
    name: 'powerchair_type',
    type: 'list',
    algoliaFacetName: 'specs.powerchair_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Powerchair Type'
  },

  'scooter_type': {
    name: 'scooter_type',
    type: 'list',
    algoliaFacetName: 'specs.scooter_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Scooter Type'
  },

  'chair_type': {
    name: 'chair_type',
    type: 'list',
    algoliaFacetName: 'specs.chair_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Chair Type'
  },

  'chair_motor': {
    name: 'chair_motor',
    type: 'list',
    algoliaFacetName: 'specs.chair_motor',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Motor',
    existingFilterPrefix: 'Motor',
  },

  'bed_type': {
    name: 'bed_type',
    type: 'list',
    algoliaFacetName: 'specs.bed_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Bed Type'
  },

  'power_add_on_type': {
    name: 'power_add_on_type',
    type: 'list',
    algoliaFacetName: 'specs.power_add_on_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Add-On Type'
  },

  'stairlift_style': {
    name: 'stairlift_style',
    type: 'list',
    algoliaFacetName: 'specs.stairlift_style',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Style',
    existingFilterPrefix: 'Style'
  },

  'hoist_type': {
    name: 'hoist_type',
    type: 'list',
    algoliaFacetName: 'specs.hoist_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Hoist Type',
  },

  'cushion_type': {
    name: 'cushion_type',
    type: 'list',
    algoliaFacetName: 'specs.cushion_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Cushion Type',
  },

  'backrest_type': {
    name: 'backrest_type',
    type: 'list',
    algoliaFacetName: 'specs.backrest_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Backrest Type',
  },

  'accessory_type': {
    name: 'accessory_type',
    type: 'list',
    algoliaFacetName: 'specs.accessory_type',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Accessory Type',
  },

  'crash_tested': {
    name: 'crash_tested',
    type: 'string',
    algoliaFacetName: 'specs.crash_tested',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    friendlyNames: {
        'true': 'Yes',
        'false': 'No'
    },
    filterTitle: 'Crash Tested?',
    existingFilterPrefix: 'Crash Tested'
  },

  'frame_material': {
    name: 'frame_material',
    type: 'list',
    algoliaFacetName: 'specs.frame_material',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Frame Material',
    existingFilterPrefix: 'Material'
  },

  'drive': {
    name: 'drive',
    type: 'list',
    algoliaFacetName: 'specs.drive',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Drive',
    existingFilterPrefix: 'Drive'
  },

  'functionality': {
    name: 'functionality',
    type: 'list',
    algoliaFacetName: 'specs.functionality',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Functionality',
    existingFilterPrefix: 'Functions'
  },

  'indoor_outdoor': {
    name: 'indoor_outdoor',
    type: 'list',
    algoliaFacetName: 'specs.indoor_outdoor',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Usage',
    existingFilterPrefix: 'Usage'
  },

  'made_to_measure': {
    name: 'made_to_measure',
    type: 'list',
    algoliaFacetName: 'specs.made_to_measure',
    controlType: 'checkboxes',
    facetCountMode: 'excludeCurrent',
    filterTitle: 'Made to Measure?'
  },

  'speed_mph': {
    name: 'speed_mph',
    type: 'list',
    algoliaFacetName: 'specs.speed_mph_band',
    facetCountMode: 'excludeCurrent',
    controlType: 'checkboxes',
    filterTitle: 'Speed (mph)',
    existingFilterPrefix: 'Speed',
    bands: {
      default: {
        '4mph': {min: 0, max: 4, friendlyName: '4 mph'},
        '6mph': {min: 4.01, max: 6.99, friendlyName: '6 mph'},
        '8mph': {min: 7, max: null, friendlyName: '8 mph'}
      },
      power_add_on: {
        '3mph': {min: 0, max: 3.49, friendlyName: '3 mph'},
        '4mph': {min: 3.5, max: 4.49, friendlyName: '4 mph'},
        '5mph': {min: 4.5, max: 5.49, friendlyName: '5 mph'},
        '6mph': {min: 5.5, max: 6.99, friendlyName: '6 mph'},
        '8mph': {min: 7, max: 8, friendlyName: '8 mph'},
        '12mph': {min: 8.01, max: null, friendlyName: '12 mph'}
      }
    }
  },

  'weight_capacity_kg': {
    name: 'weight_capacity_kg',
    type: 'list',
    algoliaFacetName: 'specs.weight_capacity_kg_band',
    facetCountMode: 'excludeCurrent',
    controlType: 'checkboxes',
    filterTitle: 'Weight Capacity (kg)',
    existingFilterPrefix: 'Weight Capacity',
    bands: {
      default: {
        '0kg': {min: 0, max: 79.99, friendlyName: 'Less than 80 kg'},
        '80kg': {min: 80, max: 99.99, friendlyName: '80 - 100 kg'},
        '100kg': {min: 100, max: 114.99, friendlyName: '100 - 114 kg'},
        '115kg': {min: 115, max: 134.99, friendlyName: '115 - 134 kg'},
        '135kg': {min: 135, max: 149.99, friendlyName: '135 - 149 kg'},
        '150kg': {min: 150, max: 169.99, friendlyName: '150 - 169 kg'},
        '170kg': {min: 170, max: 199.99, friendlyName: '170 - 199 kg'},
        '200kg': {min: 200, max: 249.99, friendlyName: '200 - 249 kg'},
        'over250kg': {min: 250, max: null, friendlyName: 'Over 250 kg'}
      },
      manual_wheelchair: {
        '0kg': {min: 0, max: 79.99, friendlyName: 'Less than 80 kg'},
        '80kg': {min: 80, max: 99.99, friendlyName: '80 - 99 kg'},
        '100kg': {min: 100, max: 114.99, friendlyName: '100 - 114 kg'},
        '115kg': {min: 115, max: 124.99, friendlyName: '115 - 124 kg'},
        '125kg': {min: 125, max: 159.99, friendlyName: '125 - 159 kg'},
        '160kg': {min: 160, max: 179.99, friendlyName: '160 - 179 kg'},
        '180kg': {min: 180, max: 199.99, friendlyName: '180 - 199 kg'},
        '200kg': {min: 200, max: 249.99, friendlyName: '200 - 249 kg'},
        'over250kg': {min: 250, max: null, friendlyName: 'Over 250 kg'}
      },
      active_wheelchair: {
        '0kg': {min: 0, max: 84.99, friendlyName: 'Less than 85 kg'},
        '85kg': {min: 85, max: 99.99, friendlyName: '85 - 99 kg'},
        '100kg': {min: 100, max: 109.99, friendlyName: '100 - 109 kg'},
        '110kg': {min: 110, max: 124.99, friendlyName: '110 - 124 kg'},
        '125kg': {min: 125, max: 139.99, friendlyName: '125 - 139 kg'},
        '140kg': {min: 140, max: 159.99, friendlyName: '140 - 159 kg'},
        '160kg': {min: 160, max: null, friendlyName: 'Over 160 kg'}
      },
      powerchair: {
        '0kg': {min: 0, max: 99.99, friendlyName: 'Less than 100 kg'},
        '100kg': {min: 100, max: 114.99, friendlyName: '100 - 114 kg'},
        '115kg': {min: 115, max: 124.99, friendlyName: '115 - 124 kg'},
        '125kg': {min: 125, max: 135.99, friendlyName: '125 - 134 kg'},
        '136kg': {min: 136, max: 149.99, friendlyName: '135 - 149 kg'},
        '150kg': {min: 150, max: 159.99, friendlyName: '150 - 159 kg'},
        '160kg': {min: 160, max: 199.99, friendlyName: '160 - 199 kg'},
        'over200kg': {min: 200, max: null, friendlyName: 'Over 200 kg'}
      },
      scooter: {
        '0kg': {min: 0, max: 114.99, friendlyName: 'Less than 115kg'},
        '115kg': {min: 115, max: 124.99, friendlyName: '115 - 124 kg'},
        '125kg': {min: 125, max: 134.99, friendlyName: '125 - 134 kg'},
        '135kg': {min: 135, max: 149.99, friendlyName: '135 - 149 kg'},
        '150kg': {min: 150, max: 159.99, friendlyName: '150 - 159 kg'},
        '160kg': {min: 160, max: 169.99, friendlyName: '160 - 169 kg'},
        '170kg': {min: 170, max: 179.99, friendlyName: '170 - 179 kg'},
        '180kg': {min: 180, max: 199.99, friendlyName: '180 - 199 kg'},
        '200kg': {min: 200, max: 219.99, friendlyName: '200 - 219 kg'},
        '220kg': {min: 220, max: 249.99, friendlyName: '220 - 249 kg'},
        '250kg': {min: 250, max: null, friendlyName: 'Over 250 kg'}
      },
      power_add_on: {
        '0kg': {min: 0, max: 99.99, friendlyName: 'Less than 100 kg'},
        '100kg': {min: 100, max: 114.99, friendlyName: '100 - 114 kg'},
        '115kg': {min: 115, max: 134.99, friendlyName: '115 - 134 kg'},
        '135kg': {min: 135, max: 164.99, friendlyName: '135 - 164 kg'},
        '165kg': {min: 165, max: 189.99, friendlyName: '165 - 189 kg'},
        '190kg': {min: 190, max: 199.99, friendlyName: '190 - 199 kg'},
        'over200kg': {min: 200, max: null, friendlyName: 'Over 200 kg'}
      }

    }
  }

}

export default searchParamsTypes
// import url from 'url'

// let apiUrl = 'https://wheelfreedom2020api.azurewebsites.net'
// apiUrl = 'http://localhost:1338'

// // Dev mode
// if(
//     (typeof window !== 'undefined' && document.URL.indexOf('localhost') > -1) ||    // Client
//     (process.env.NODE_ENV === 'development')                                        // Server (but not when running in production)
// ){
//     apiUrl = 'http://localhost:1338'
// }


// // Local network - doesn't work for page refreshes because we don't have access to Node request object
// if(typeof window !== 'undefined' && document.URL.indexOf('192.168') > -1){
//     apiUrl = 'http://192.168.0.22:1338'
// }

const config = {

  apiUrl: process.env.NEXT_PUBLIC_API_URL,

  logging: {
    groups: {
      // auth: {
      //     label: 'Auth'
      // },
      // data: {
      //     label: 'Data'
      // },
      // search: {
      //     label: 'Search'
      // }
    }
  },

  blog: {
    resultsPerPage: 24
  },

  search: {
    resultsPerPage: 10
  },

  algolia: {
    appId: 'Q9NV810T3H',
    searchApiKey: 'cc72453c8072ff60d3d818e084a57246'
  },

  storyBlok: {
    apiUrl: 'https://api.storyblok.com/v1/cdn',
    publicToken: 'q52LfAQzzbXkiFgxaydlJQtt'
  }

}

export default config;
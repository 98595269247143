import algoliaSearch from 'algoliasearch';

import config from 'settings/config';

const algoliaClient = algoliaSearch(config.algolia.appId, config.algolia.searchApiKey);
const algoliaIndex = algoliaClient.initIndex('products');


type AlgoliaGetResultsSettings = {
  indexName: string
};

class AlgoliaService {

  convertHitsToProducts(items: any[] = []): Product[] {

    items = items.map(item => {

      let product: Product = copyPropertiesOrNull(item, [
        ['id','id'],
        ['uuid','objectID'],
        ['slug','slug'],
        ['fullSlug','full_slug'],
        ['name','name'],
        ['productType','productType'],
        ['summary','summary'],
        ['status','status'],
        ['keyFeatures','keyFeatures'],
        ['specs','specs'],
        ['availability','availability']
      ]);

      product.createdDateTime = new Date(item.created_timestamp).toISOString();
      product.updatedDateTime = new Date(item.updated_timestamp).toISOString();

      product.images = item.image_gallery?.map(image => ({
        image: {
          url: image.image?.filename ?? null,
          alt: image.image?.alt ?? null
        }, 
        caption: image.caption
      })) ?? [];

      product.pricing = {
        postage: 0
      };

      product.availability = item.availability ?? {};

      // Pricing
      if(item.pricing){

        if(item.pricing.postage){
          product.pricing.postage = parseFloat(item.pricing.postage);
        }

        if(item.pricing.buy){
          product.pricing.buy = {
            purchasePrice: parseFloat(item.pricing.buy.purchasePrice)
          };
        }

        if(item.pricing.buyUsed){
          product.pricing.buyUsed = {
            purchasePrice: parseFloat(item.pricing.buyUsed.purchasePrice)
          };
        }

        if(item.pricing.motability){
          product.pricing.motability = {
            initialPrice: parseFloat(item.pricing.motability.initialPrice),
            weeklyPrice: parseFloat(item.pricing.motability.weeklyPrice)
          };
        }

        if(item.pricing.shortTermHire){
          product.pricing.shortTermHire = {
            price1:  parseFloat(item.pricing.shortTermHire.price1),
            price2:  parseFloat(item.pricing.shortTermHire.price2),
            price3:  parseFloat(item.pricing.shortTermHire.price3)
          };
        }

        if(item.pricing.longTermHire){
          product.pricing.longTermHire = {
            oneYearInitialPrice:  parseFloat(item.pricing.longTermHire.oneYearInitialPrice),
            oneYearWeeklyPrice:  parseFloat(item.pricing.longTermHire.oneYearWeeklyPrice),
            threeYearInitialPrice:  parseFloat(item.pricing.longTermHire.threeYearInitialPrice),
            threeYearWeeklyPrice:  parseFloat(item.pricing.longTermHire.threeYearWeeklyPrice)
          };
        }

      }

      return product;

    });

    return items;

  }


  async getResults(keywords: string, params: AlgoliaQueryParams, settings: AlgoliaGetResultsSettings): Promise<Object | undefined> {

    let index = algoliaIndex;

    if(settings.indexName){
      index = algoliaClient.initIndex(settings.indexName);
    }

    const result = await index.search(keywords, params);

    return result;

  }


}

const algoliaService = new AlgoliaService();

export default algoliaService;




// Utils
const copyPropertiesOrNull = (oldObj: any, props: [string, string][]): Product => {

    let newObj = {} as Product;

    props.forEach((prop) => {
      newObj[prop[0]] = oldObj[prop[1]] ?? null;
    })

    return newObj;

};
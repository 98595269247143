import React, {useEffect} from 'react'

import {SearchContext, SearchContextValue} from 'services/searchService'



const SearchWrapper = (props) => {

    const search = SearchContextValue()

    return(
        <SearchContext.Provider value={search}>
            {props.children}
        </SearchContext.Provider>
    )

}

export default SearchWrapper

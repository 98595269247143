import { css } from 'styled-components';

import {
    spacingVertical,
    spacingBottom,
    spacingBottomHalf,
    spacingBottomDouble,
    spacingTop,
    paddingHorizontal,
    paddingVertical,
    paddingVerticalAsSpacing,
    paddingTop,
    paddingTopAsSpacing,
    paddingBottom,
    paddingOuterGutters
} from 'styles/helpers/spacing'




const styles = css`

    ${spacingVertical}
    ${spacingBottom}
    ${spacingBottomHalf}
    ${spacingBottomDouble}
    ${spacingTop}
    .spacingBottomNone{margin-bottom: 0;}

    ${paddingHorizontal}
    ${paddingVertical}
    ${paddingVerticalAsSpacing}
    ${paddingTop}
    ${paddingTopAsSpacing}
    ${paddingBottom}
    ${paddingOuterGutters}

`

export default styles
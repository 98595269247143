
import getColor from 'utils/getThemeColor';

const colors = {};

const colorVals: ObjectOf<colorVal> = {
  blue: [
    {stop: 0, h: 198, s: 100},
    {stop: 100, h: 198, s: 100}
  ],
  green: [
    {stop: 0, h: 83, s: 60},
    {stop: 100, h: 83, s: 60}
  ],
  grey: [
    {stop: 0, h: 210, s: 3},
    {stop: 100, h: 210, s: 3}
  ],
  orange: [
    {stop: 0, h: 32, s: 86},
    {stop: 100, h: 32, s: 86}
  ]
};

const breakpoints = [0, 480, 768, 1000, 1300, 1600, 1900];

const spacing = {
  outerGutterWidth: ['24px', '24px', '40px', '80px', '80px', '80px'],
  wrapperWidth: ['0', '0', '0', '0', '0', '1436px'],
  spacingHorizontal: ['24px', '24px', '30px', '40px', '40px', '40px'],
  spacingVertical: ['30px', '40px', '50px', '50px', '50px', '50px'],
  paddingHorizontal: ['24px', '24px', '30px', '30px', '30px', '30px'],
  paddingVertical: ['24px', '24px', '30px', '30px', '30px', '30px']
};

const typography = {
  fontFamilyDefault: '"Roboto", Arial, Helvetica, sans-serif',
  fontFamilySecondary: '"Roboto Condensed", Arial, Helvetica, sans-serif'
};

const theme = {
  typography, 
  colors, 
  spacing, 
  breakpoints
};

const color = {
  get: getColor,
  blue: (lightness) => getColor(colorVals.blue, lightness),
  green: (lightness) => getColor(colorVals.green, lightness),
  grey: (lightness) => getColor(colorVals.grey, lightness),
  orange: (lightness) => getColor(colorVals.orange, lightness),

  black: 'rgba(0, 0, 0, 1)',    
  white: 'rgba(255, 255, 255, 1)',

  primary: getColor(colorVals.blue, 45),
  secondary: getColor(colorVals.green, 50),
  tertiary: getColor(colorVals.grey, 50),

  text: getColor(colorVals.grey, 30),
  selectedText: getColor(colorVals.blue, 50),
  selectedTextBackground: 'rgba(255, 255, 255, 1)',

  buttonText: 'rgba(255, 255, 255, 1)',
  buttonBackground: getColor(colorVals.grey, 50),
  buttonBackgroundHover: getColor(colorVals.grey, 70),

  pageBackground: 'rgba(244, 247, 247, 1)',

  lines: getColor(colorVals.grey, 90),

  facebook: 'hsl(221, 43.88%, 41.7%)',
  twitter: 'hsl(206, 80.96%, 63.24%)'

};


const bp = (index: number, upto = false) => {
  return breakpoints[upto ? index : index-1] + 'px';
};

export {bp, color};

export default theme; 
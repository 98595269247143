import { createGlobalStyle } from 'styled-components';

import resetCss from 'styles/reset';
import spacingCss from 'styles/spacing'
import typographyCss from 'styles/typography'

import theme, {bp, color} from 'settings/theme';

const GlobalStyle = createGlobalStyle`

  ${resetCss}
  ${spacingCss}
  ${typographyCss}


  html, body{
    background-color: white;
  }

    
  /* Remove this once Next javascript bug is fixed. */
  /* https://github.com/vercel/next.js/issues/13680 */
  body{display:block !important;}

  .main{}

  .faded50{
    opacity: 0.5;
  }

  .faded75{
    opacity: 0.75;
  }


  .content{

    b, strong{
      font-weight: 800;
    }

    a{
      text-decoration: underline;
      color: ${color.primary};
    }

    p{

      margin-bottom: 1.5rem;

      &:last-item{
        margin-bottom: 0;
      }

    }

    .content-indent{
      display: inline-block;
      margin-left: 2rem;
    }

    hr{
      margin:0;
      padding: 0;
      height: 1rem;
      opacity: 0;
    }

    ul{

      margin-left: 2rem;
      margin-bottom: 2rem;

      li{

        margin-bottom: 1rem;
        list-style: disc;

        p:not(:last-child){
          margin-bottom: 1rem;
        }

        p:last-child{
          margin-bottom: 0;
        }

      }

    }

    h1, h2, h3, h4, h5, h6{
      margin-bottom: 1rem;
    }

  }

  .drift-frame-controller{

      display: none !important;

      @media (min-width: 1000px) {
          display: block !important;
      }

  }

`;

export default GlobalStyle;

import React, {useContext, useState} from 'react';

type LayoutState = {
  isMainMenuOpen: boolean,
  setIsMainMenuOpen: (val: boolean) => void
  
};

const defaultInitialState = {
  isMainMenuOpen: false,
  setIsMainMenuOpen: () => {}
};

const LayoutContext = React.createContext<LayoutState>(defaultInitialState);

type Props = {
  children?: React.ReactNode
};

const LayoutProvider: React.FC<Props> = (props) => {

  const [isMainMenuOpen, setIsMainMenuOpenInternal] = useState<boolean>(false);

  const setIsMainMenuOpen = (val: boolean) => {
    setIsMainMenuOpenInternal(val);
  };

  const ctx: LayoutState = {
    isMainMenuOpen,
    setIsMainMenuOpen
  }

  return (
    <LayoutContext.Provider value={ctx}>
      <>{props.children}</>
    </LayoutContext.Provider>  
  )

}

const useLayout = () => {
  const context = useContext(LayoutContext)
  return context
}

export type { LayoutState }
export default useLayout
export { LayoutContext, LayoutProvider }
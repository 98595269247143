
const productTypes = {

  "all": {
    paramTypes: [
      // 'price',
      'availability',
      'region',
      'product_type',
      'manufacturer'
    ]
  },

  "scooter": {
    paramTypes: [
      'scooter_type',
      'speed_mph',
      'weight_capacity_kg'
    ]
  },

  "manual-wheelchair": {
    paramTypes: [
      'wheelchair_type',
      'weight_capacity_kg',
      'crash_tested'
    ]
  },

  "active-wheelchair": {
    paramTypes: [
      'active_wheelchair_type',
      'weight_capacity_kg',
      'frame_material'
    ]
  },

  "powerchair": {
    paramTypes: [
      'powerchair_type',
      'speed_mph',
      'weight_capacity_kg',
      'drive',
      'made_to_measure',
      'functionality',
      'indoor_outdoor'
    ]
  },

  "ramp": {
    paramTypes: [
      'weight_capacity_kg'
    ]
  },

  "bed": {
    paramTypes: [
      'bed_type',
      'weight_capacity_kg'
    ]
  },

  "chair": {
    paramTypes: [
      'chair_type',
      'weight_capacity_kg',
      'made_to_measure',
      'chair_motor'
    ]
  },

  "power-add-on": {
    paramTypes: [
      'power_add_on_type',
      'weight_capacity_kg',
      'speed_mph'
    ]
  },

  "knee-scooter": {
    paramTypes: [
      'weight_capacity_kg'
    ]
  },

  "rollator": {
    paramTypes: [
      'weight_capacity_kg',
      'frame_material'
    ]
  },

  "stairlift": {
    paramTypes: [
      'stairlift_style',
      'weight_capacity_kg'
    ]
  },

  "hoist": {
    paramTypes: [
      'hoist_type',
      'weight_capacity_kg'
    ]
  },

  "cushion": {
    paramTypes: [
      'cushion_type',
      'weight_capacity_kg'
    ]
  },

  "backrest": {
    paramTypes: [
      'backrest_type',
      'weight_capacity_kg'
    ]
  },

  "accessory": {
    paramTypes: [
      'accessory_type'
    ]
  }

}

export default productTypes;
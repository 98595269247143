import React, { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { LayoutProvider } from 'providers/layout/layoutProvider';

import GlobalStyle from 'styles/globalstyles';

import SearchServiceWrapper from 'services/searchServiceWrapper';

import { Roboto_Condensed } from 'next/font/google';

const FB_PIXEL_ID = '4874216722674950';

const robotoCondensed = Roboto_Condensed({ weight: ['400', '700'], subsets: ['latin'] });

type AppPropsExtended<P = {}> = (import('next/app').AppProps<P>) & {
  Component: NextPageExtended<P>
}

const initGA = (url) => {
  if (typeof window !== 'undefined') {
    // Your existing Google Analytics logic here
    setTimeout(() => {
      (window as any).gtag('config', 'UA-3061570-1', {
        page_location: url,
        page_title: document.title,
      });
    }, 0);
  }
};


const MyApp = ({ Component, pageProps }: AppPropsExtended<{session: any}>) => {

  const getLayout = Component.getLayout ?? ((page) => page);
  const { events, pathname } = useRouter();


  // Google Analytics
  useEffect(() => {
    // Initialize Google Analytics when the app mounts
    initGA(pathname);

    // Attach route change event listener
    const handleRouteChange = (url: string) => {
      initGA(url);
    };

    events.on('routeChangeComplete', handleRouteChange);

    // Clean up event listener when the component unmounts
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };

  }, [events]);



  return (
    <LayoutProvider>
      <SearchServiceWrapper>

        <Head>
          <meta name="viewport" content='initial-scale=1.0, width=device-width, height=device-height' />
        </Head>

        <Script id="facebook-pixel">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
            fbq('track', 'PageView');
          `}
        </Script>

        {/* Logging */}
        <Script id="versionNumber">
          {` console.log("Version: 1.0.4"); `}
        </Script>

        <style jsx global>{`
          html {
            font-family: ${robotoCondensed.style.fontFamily};
          }
        `}</style>

        <GlobalStyle />

        {getLayout(<Component key={pathname} {...pageProps}></Component>)}

      </SearchServiceWrapper>
    </LayoutProvider>
  );


}

export default MyApp;

const getUnits = (val) => {
  return val.replace(/\d+|^\s+|\s+$/g,'');
};

const removeUnits = (val) => {
  return parseInt(val.replace(/\D/g,''));
};

const multiplyValueWithUnits = (val, multiplier) => {
  return removeUnits(val) * multiplier + getUnits(val);
};

export {
    getUnits, 
    removeUnits,
    multiplyValueWithUnits
};